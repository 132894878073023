export const apiBaseUrl = window.location.href.indexOf('localhost') !== -1 ? 'https://localhost:44389' : window.location.protocol + '//' + window.location.host + '/api';

export const CONSTANTS = {
    sortBy: {
        alphabet: 'alphabet',
        mostSold: 'mostSold',
        mostProgrammed: 'mostProgrammed',
        newest: 'newest'
    }
}

export const ROUTES = {
    allMovies: '/alle-film',
    program: '/program',
    movie: '/film-info'
}