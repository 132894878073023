import React from 'react'

export default class MovieContainer extends React.Component {
    constructor() {
        super();
    }

    render() {
        const { } = this.props;

        return (
            <div className="MovieContainer">
                <h1>Coming soon!</h1>
            </div>
        );
    }
}