import './styles/general.scss'
import React from 'react';
import { Route } from 'react-router-dom';
import AllMoviesContainer from './components/movielists/containers/AllMoviesContainer';
import ProgramContainer from './components/movielists/containers/ProgramContainer';
import MovieContainer from './components/movie/MovieContainer'

export default function App() {
    return (
        <div className="App">
            <Route path="/alle-film" component={AllMoviesContainer} />
            <Route path="/program" component={ProgramContainer} />
            <Route path="/film-info" component={MovieContainer} />
        </div>
    );
}