import '../movielist.scss';
import React from 'react';
import axios from 'axios';
import moment from 'moment';
import _ from 'lodash'
import { CONSTANTS, apiBaseUrl } from '../../../app/constants'
//import { init } from '../../../app/dataStore'
import Spinner from '../../overlays/Spinner'
import MovieList from '../MovieList';
import RegionPicker from '../filters/RegionPicker'
import CinemaPicker from '../filters/CinemaPicker'
import SortPicker from '../filters/SortPicker'

export default class AllMoviesContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            // initial data
            allMovies: [],
            allRegions: [],
            allCinemas: [],
            allShowtimes: [],
            showtimesByCinema: {},
            cinemasByRegion: {},
            //--

            // filtered data
            movies: [],
            showtimes: [],
            cinemas: [],
            regions: [],
            selectedCinemas: [],
            sortBy: CONSTANTS.sortBy.alphabet,
            sortDirection: 'asc'
        }

        this.regionsChanged = this.regionsChanged.bind(this);
        this.cinemasChanged = this.cinemasChanged.bind(this);
        this.sortByChanged = this.sortByChanged.bind(this);
        this.sortDirectionChanged = this.sortDirectionChanged.bind(this);
    }

    componentDidMount() {
        // Either get init here and state is the grand holder
        // Or we use redux and have a shared "init store" 
        // or maybe our own lightweight shared data store?

        axios.get(apiBaseUrl + `/init`)
            .then((response) => {
                const { organizers, movies, showtimes, regions } = response.data;
                this.setState({
                    loading: false,
                    allMovies: movies,
                    allCinemas: organizers,
                    allShowtimes: showtimes,
                    allRegions: regions,
                    cinemas: organizers,
                    movies: _.sortBy(movies, [x => x.name.toLowerCase()]),
                    showtimes,
                    regions,
                    showtimesByCinema: _.groupBy(showtimes, (showtime) => showtime.organizerId),
                    cinemasByRegion: _.groupBy(organizers, (org) => org.region)
                });
            })
            .catch((error) => alert('Der skete en fejl. Prøv igen senere.'));
    }

    regionsChanged(regions) {
        const { allCinemas, selectedCinemas, cinemasByRegion, sortBy, sortDirection } = this.state;
        let cinemasInRegion = [];

        if (selectedCinemas.length > 0) cinemasInRegion = _.union(cinemasInRegion, selectedCinemas);
        if (selectedCinemas.length == 0 && regions.length == 0) cinemasInRegion = allCinemas;
        else regions.forEach(region => cinemasInRegion = _.union(cinemasInRegion, cinemasByRegion[region]));

        const { movies, showtimes } = this.filterBy(cinemasInRegion.map(cinema => cinema.id));
        const sortedMovies = this.sortBy(sortBy, sortDirection, movies, showtimes);

        this.setState({
            cinemas: _.sortBy(cinemasInRegion, [x => x.name.toLowerCase()]),
            movies: sortedMovies,
            showtimes
        });
    }

    cinemasChanged(cinemaIds) {
        const { allCinemas, sortBy, sortDirection } = this.state;
        const selectedCinemas = allCinemas.filter(x => cinemaIds.includes(x.id));
        const { movies, showtimes } = this.filterBy(cinemaIds);
        const sortedMovies = this.sortBy(sortBy, sortDirection, movies, showtimes);

        this.setState({
            selectedCinemas,
            movies: sortedMovies,
            showtimes
        });
    }

    sortByChanged(sort) {
        const { sortDirection, movies, showtimes } = this.state;
        const sortedMovies = this.sortBy(sort, sortDirection, movies, showtimes);

        this.setState({
            sortBy: sort,
            movies: sortedMovies
        });
    }

    sortDirectionChanged(direction) {
        const { sortBy, movies, showtimes } = this.state;
        const sortedMovies = this.sortBy(sortBy, direction, movies, showtimes);

        this.setState({
            sortDirection: direction,
            movies: sortedMovies
        });
    }

    filterBy(cinemaIds) {
        const { allMovies, allShowtimes, showtimesByCinema } = this.state;
        let movieIds = [];
        const showtimes = [];

        cinemaIds.forEach(id => {
            const showtimesInCinema = showtimesByCinema[id];
            if (showtimesInCinema) {
                showtimesInCinema.forEach(showtime => {
                    showtimes.push(showtime);

                    if (!movieIds.includes(showtime.movieId))
                        movieIds.push(showtime.movieId);
                });
            }
        });

        const movies = movieIds.length > 0 ? allMovies.filter(movie => movieIds.includes(movie.id)) : allMovies;
        return { movies, showtimes: showtimes.length > 0 ? showtimes : allShowtimes };
    }

    sortBy(sortBy, sortDirection, movies, showtimes) {
        const inverted = [sortDirection === 'asc' ? 'desc' : 'asc'];
        let sortedMovies = [];

        switch (sortBy) {
            case CONSTANTS.sortBy.alphabet:
                sortedMovies = _.orderBy(movies, [x => x.name.toLowerCase()], sortDirection);
                break;
            case CONSTANTS.sortBy.mostSold:
                const orderedShowtimes = _.orderBy(showtimes, 'soldSeats', inverted);
                sortedMovies = _.orderBy(movies, movie => _.findIndex(orderedShowtimes, showtime => movie.id == showtime.movieId));
                break;
            case CONSTANTS.sortBy.mostProgrammed:
                const showtimeCountByMovieId = _(showtimes).countBy('movieId')
                    .map((count, movieId) => ({ count, movieId })).orderBy('count', inverted).value();

                sortedMovies = _.orderBy(movies, movie => _.findIndex(showtimeCountByMovieId, showtime => movie.id == showtime.movieId));
                break;
            case CONSTANTS.sortBy.newest:
                sortedMovies = _.orderBy(movies, [x => moment(x.premiere)], sortDirection);
                break;
        }
        return sortedMovies;
    }

    render() {
        const { loading, movies, regions, cinemas, sortDirection } = this.state;

        return (
            <div className="AllMoviesContainer">
                <Spinner show={loading} />

                <div className="filters">
                    <RegionPicker regions={regions} regionsChanged={this.regionsChanged} />
                    <CinemaPicker cinemas={cinemas} cinemasChanged={this.cinemasChanged} />
                </div>

                <div className="title-and-sort">
                    <h1>Alle aktuelle film</h1>
                    <SortPicker direction={sortDirection}
                        sortChanged={this.sortByChanged}
                        directionChanged={this.sortDirectionChanged} />
                </div>

                <MovieList movies={movies} />
            </div>
        )
    }
}