import React from 'react';
import { CONSTANTS } from '../../../app/constants'
import { customStyles, customTheme } from './Select'
import Select from 'react-select'
import sortIcon from '../../../assets/images/icons/sort.svg'

export default function SortPicker({ direction, sortChanged, directionChanged }) {
    const options = [
        { value: CONSTANTS.sortBy.alphabet, label: 'Alfabetisk sortering' },
        { value: CONSTANTS.sortBy.mostSold, label: 'Mest solgte' },
        { value: CONSTANTS.sortBy.mostProgrammed, label: 'Mest programlagte' },
        { value: CONSTANTS.sortBy.newest, label: 'Nyeste' }
    ]

    const directionClicked = (e) => {
        const newDirection = direction === 'asc' ? 'desc' : 'asc';
        e.target.className = `icon-sort ${newDirection}`;
        directionChanged(newDirection);
    }

    return (
        <div className="SortPicker">
            <img className="icon-sort" src={sortIcon} onClick={directionClicked} />
            <Select name="sort"
                className="react-select-container"
                classNamePrefix="react-select"
                defaultValue={options[0]}
                options={options}
                styles={{
                    ...customStyles,
                    menu: (provided, state) => ({
                        ...provided,
                        backgroundColor: '#262626'
                    })
                }}
                theme={customTheme}
                onChange={(e) => sortChanged(e.value)}
            />
        </div>
    );
}