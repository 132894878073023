import React from 'react';
import { Option, ValueContainer, customStyles, customTheme } from './Select'
import Select from 'react-select'

export default function RegionPicker({ regions, regionsChanged }) {
    const options = regions.map(region => ({ value: region, label: region }));

    return (
        <div className="RegionPicker">
            <h3>Vælg region</h3>
            <Select name="regions"
                className="react-select-container"
                classNamePrefix="react-select"
                options={options}
                styles={customStyles}
                theme={customTheme}
                placeholder="Vælg region"
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{ ValueContainer, Option }}
                onChange={(e) => regionsChanged(e.map(selected => selected.value))}
            />
        </div>
    );
}