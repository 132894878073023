import React, { Fragment } from 'react';
import axios from 'axios';
import moment from 'moment';
import MovieList from '../MovieList';
import RegionPicker from '../filters/RegionPicker'
import CinemaPicker from '../filters/CinemaPicker'
import SortPicker from '../filters/SortPicker'
import DatePicker from '../filters/datepicker/DatePicker'

export default class ProgramContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            date: moment(),
            movies: [],
            regions: [],
            cinemas: []
        }
    }

    componentDidMount() {

    }

    render() {
        const { date, movies, regions, cinemas, sortBy } = this.state;

        return (
            <div className="ProgramContainer">
                <DatePicker
                    selectedDate={date}
                />

                <RegionPicker
                    selectedRegions={regions}
                    regionsChanged={this.regionsChanged}
                />

                <CinemaPicker
                    selectedCinemas={cinemas}
                    cinemasChanged={this.cinemaChanged}
                />

                <div>
                    <h1>Program</h1>
                    <SortPicker
                        selectedSort={sortBy}
                        sortByChanged={this.sortByChanged}
                    />
                </div>

                <MovieList movies={movies} />
            </div>
        )
    }
}