import React from 'react';
import { Option, ValueContainer, customStyles, customTheme } from './Select'
import Select from 'react-select'

export default function CinemaPicker({ cinemas, cinemasChanged }) {
    const options = cinemas.map(bio => { return { value: bio.id, label: `${bio.name} / ${bio.address.city}` } });

    return (
        <div className="CinemaPicker">
            <h3>Vælg biograf</h3>
            <Select name="cinemas"
                className="react-select-container"
                classNamePrefix="react-select"
                defaultValue={options[0]}
                options={options}
                styles={customStyles}
                theme={customTheme}
                placeholder="Vælg biograf"
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{ ValueContainer, Option }}
                onChange={(e) => cinemasChanged(e.map(selected => selected.value))}
            />
        </div>
    );
}