import React from 'react';
import _ from 'lodash'
import { components } from "react-select";

export const Option = (props) => {
    return (
        <components.Option {...props}>
            <div className="Checkbox">
                <input type="checkbox" checked={props.isSelected} />
                <span></span>
                {props.label}
            </div>
        </components.Option>
    );
};

export const ValueContainer = ({ children, getValue, hasValue, ...props }) => {
    var valueLength = getValue().length;
    const newChildren = [...children];
    newChildren[0] = <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }}>
        <span style={{
            width: '20px',
            height: '20px',
            paddingTop: '2px',
            paddingLeft: '1px',
            paddingLeft: '1px',
            backgroundColor: 'white',
            color: 'black',
            borderRadius: '2px',
            textAlign: 'center',
            marginRight: '10px',
            fontSize: '14px'
        }}>{valueLength}</span> Valgt{valueLength != 1 ? "e" : ""}
    </div>;

    return (
        <components.ValueContainer {...props}>
            {hasValue ? newChildren : children}
        </components.ValueContainer>
    );
};

export const customStyles = {
    control: (provided, state) => ({
        ...provided,
        minHeight: 'none',
        height: '34px',
        borderRadius: '2px',
        backgroundColor: 'transparent',
        border: '1px solid #757575',
        boxShadow: 'none',
        cursor: 'pointer'
    }),
    menu: (provided, state) => ({
        ...provided,
        borderRadius: '2px',
        backgroundColor: '#3b3b3b',
        border: '1px solid #757575',
        marginTop: 0,
        borderTop: 'none'
    }),
    option: (provided, { isFocused, isSelected }) => ({
        ...provided,
        color: isSelected ? 'white' : '#999999',
        padding: '5px 12px',
        cursor: 'pointer',
        backgroundColor: isFocused ? '#444' : null,
        display: 'flex',
        alignItems: 'center'
    }),
    singleValue: (provided, state) => ({
        ...provided,
        color: '#999999'
    }),
    multiValue: (provided, state) => ({
        ...provided,
        color: '#999999'
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        padding: '0 12px',
        display: 'flex'
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        color: '#757575',
        padding: '0 6px'
    }),
    indicatorSeparator: (provided, state) => ({
        ...provided,
        backgroundColor: '#757575'
    }),
    clearIndicator: (provided, state) => ({
        ...provided,
        color: '#757575',
        padding: '0 6px'
    }),
}

export const customTheme = theme => ({
    ...theme,
    colors: {
        ...theme.colors,
        primary: '#757575',
        primary50: '#666'
    },
})