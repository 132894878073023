import React from 'react'
import MovieListItem from './MovieListItem'

export default function MovieList({ movies }) {
    
    return (
        <div className="MovieList">
            {movies.length > 0 ? movies.map(movie => <MovieListItem key={movie.id} movie={movie} />) : 'Der er ingen film.'}
        </div>
    )
}