import React from 'react';

export default function DatePicker({ selectedDate, dateChanged }) {


    return (
        <div className="DatePicker">

        </div>
    );
}