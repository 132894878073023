import React from 'react'
import { ROUTES } from '../../app/constants'
import { Link } from 'react-router-dom'

export default function MovieListItem({ movie }) {


    return (
        <Link to={`${ROUTES.movie}/${movie.id}`} className="MovieListItem">
            <div className="Poster">
                <img src={movie.posters.large || movie.posters.small} alt="filmplakat" />
            </div>
            <h2>{movie.name}</h2>
            <input className="Button" type="button" value="Billetter" />
        </Link>
    )
}